<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
  <v-form ref="eventTime">
    <base-material-card icon="mdi-clock-time-four">
      <slot slot="title"><a @click="eventLink()">{{event.name}}</a> / {{utils.timezoneDateToShortString(eventTime.start, eventTime.timezone)}}</slot>
      <slot slot="rightButtons" v-if="eventTime.id">
        <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
      </slot>
      <div v-if="showEditToggle">

        <div :class="{'d-flex': $vuetify.breakpoint.smAndUp}">
          <div class="d-flex align-center">
            <span class="small mr-2">Timezone</span> 
            <v-select v-model="timezone" :items="timezones" item-value="value" item-text="text"/>
          </div> 
        </div>

        <div :class="{'d-flex': $vuetify.breakpoint.smAndUp}">
          <div class="d-flex">
            <span class="small mr-2">Event starts</span> 
            <datetime input-id="start" class="mb-5 mr-2" input-style="border-bottom:1px solid" 
            v-model="start" type="datetime" :max-datetime="end" :zone="timezone"/>
          </div> 
          <div class="d-flex">
            <span class="small mr-2"> and ends </span>
            <datetime input-id="end" class="mb-5" input-style="border-bottom:1px solid" v-model="end" type="datetime" :min-datetime="start" :zone="timezone"/>
          </div> 
        </div>

        <div class="d-flex"><v-switch v-model="hideDates"></v-switch><div class="pt-5" style="valign:middle">Hide dates?
          <tooltip left>Would you like to hide these dates on the event  page?</tooltip></div>
        </div>

        <v-row class="mt-5">
          <v-col cols="auto">
            <v-btn color="success" @click="updateEventTime">Save</v-btn>
            <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
          </v-col>
          <v-spacer/>
          <v-col cols="auto">
            <v-btn class="mr-0" color="error" @click="deleteEventTime">Delete</v-btn>
          </v-col>
        </v-row>
      
      </div>
      <div v-else>
          
        <v-row>
          <v-col cols="3">Event starts</v-col>
          <v-col cols="9">{{utils.timezoneDateToShortString(eventTime.start, eventTime.timezone)}} and ends {{utils.timezoneDateToShortString(eventTime.end, eventTime.timezone)}} {{timezones.find(f => f.value === timezone).text}}</v-col>
        </v-row>

        <v-row>
          <v-col cols="3">Calendar Links</v-col>
          <v-col cols="9">
            <v-btn small color="success" class="mr-5 mb-1" @click="eventUtils.googleCalendarLink(boxOffice, event, eventTime)"><v-icon class="pr-2">mdi-calendar</v-icon>Google Calendar</v-btn>
            <v-btn small color="success" @click="eventUtils.icsCalendarLink(boxOffice, event, eventTime)"><v-icon class="pr-2">mdi-calendar</v-icon>Other Calendars</v-btn>
          </v-col>
        </v-row>

          
        <v-row>
          <v-col cols="3"><b>Ticket Types</b></v-col>
        </v-row>

      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-row class="mx-n1" style="border-bottom: 1px solid lightgrey">
          <v-col cols="4">Name</v-col>
          <v-col cols="2" class="text-right">Total</v-col>
          <v-col cols="2" class="text-right">Sold</v-col>
          <v-col cols="2" class="text-right">Avail</v-col>
          <v-col cols="2" class="text-right">%Sold</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(ticketType, i) in ticketTypes" :key="i" @click="selectTicketType(ticketType)">
              <v-col cols="4">{{ticketType.name}}</v-col>
              <v-col cols="2" class="text-right">{{avail(ticketType, "quantity")}}</v-col>
              <v-col cols="2" class="text-right">{{avail(ticketType, "confirmedTickets")}}</v-col>
              <v-col cols="2" class="text-right">{{avail(ticketType, "availableTickets")}}</v-col>
              <v-col cols="2" class="text-right">{{percentSold(ticketType)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row class="mx-n1" style="border-bottom: 1px solid lightgrey">
          <v-col cols="2">Name</v-col>
          <v-col cols="2">Total Tickets</v-col>
          <v-col cols="2">Confirmed</v-col>
          <v-col cols="2">Unconfirmed</v-col>
          <v-col cols="2">Available</v-col>
          <v-col cols="2">% Sold</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(eventTimeTicketType, i) in eventTimeTicketTypes" :key="i" @click="selectTicketType(eventTimeTicketType)">
              <v-col cols="2">{{eventTimeTicketType.ticketType.name}}</v-col>
              <v-col cols="2">{{avail(eventTimeTicketType.ticketType, "quantity")}}</v-col>
              <v-col cols="2">{{avail(eventTimeTicketType.ticketType, "confirmedTickets")}}</v-col>
              <v-col cols="2">{{avail(eventTimeTicketType.ticketType, "unconfirmedTickets")}}</v-col>
              <v-col cols="2">{{avail(eventTimeTicketType.ticketType, "availableTickets")}}</v-col>
              <v-col cols="2">{{percentSold(eventTimeTicketType.ticketType)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      </div>

    </base-material-card>
  </v-form>

  <v-dialog v-if="eventTimeTicketType" v-show="editEventTimeTicketType" v-model="editEventTimeTicketType" persistent max-width="600px">
    <v-card>
      <v-card-title>{{eventTimeTicketType.ticketType.name}} tickets<br> for {{event.name}}<br> at {{utils.timezoneDateToShortString(eventTime.start, eventTime.timezone)}}          
        <v-spacer></v-spacer>
        <v-icon large @click="editEventTimeTicketType = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p>You can customise the number of tickets for just this Event Time.  This won't affect the number of tickets for other times.  
            If you delete this customised number of tickets, the number of tickets will be reset to {{eventTimeTicketType.ticketType.quantity}}.</p>
          <text-field label="Number Of Tickets" v-model="eventTimeTicketType.quantity" :rules="[utils.integerRule]">
          <slot slot="tooltip">The number of {{eventTimeTicketType.ticketType.name}} tickets for this event at {{utils.timezoneDateToShortString(eventTime.start, eventTime.timezone)}}.</slot> 
        </text-field>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="editEventTimeTicketType = false">Cancel</v-btn>
        <v-btn v-if="eventTimeTicketType.id" color="error" @click="deleteEventTimeTicketType">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveEventTimeTicketType">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

    
    <v-form ref="ticket">
      <base-material-card icon="mdi-ticket-confirmation">
        <slot slot="title">Tickets</slot>
        <v-row class="mx-n1" style="border-bottom: 1px solid lightgrey">
          <v-col cols="3">Number</v-col>
          <v-col cols="3">Name</v-col>
          <v-col cols="3">Type</v-col>
          <v-col cols="3">Total Price</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(ticket, i) in tickets" :key="i" @click="selectItem(ticket)">
              <v-col cols="3">{{ticket.ticketNumber}}</v-col>
              <v-col cols="3">{{ticket.orderName}}</v-col>
              <v-col cols="3">{{ticket.ticketTypeName}}</v-col>
              <v-col cols="3">{{utils.priceDisplay(ticket.price + ticket.bookingFee, ticket.currency)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </base-material-card>
    </v-form>

  </v-container>

</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js';
import Store from '@/services/Store.js';
import EventUtils from '@/services/EventUtils.js';
import { getCurrencyPrefix } from '@/helpers/currency'
import timezones from '@/helpers/timezones'

  export default {
    name: 'EventTime',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  

    data () {
      return {
        timezones,
        getCurrencyPrefix,
        eventTime: {},
        event: {},
        ticketType: {},
        boxOffice: {},
        timezone: "Europe/London",
        start: null,
        end: null,
        hideDates: false,
        ticketTypes: [],
        user: {},
        showEditToggle: false,
        availability: {},
        tickets: [],
        eventTimeTicketTypes: [],
        eventTimeTicketType: null,
        editEventTimeTicketType: false,
      }
    },

    
    computed: {
      utils () {
        return Utils;
      },
      eventUtils () {
        return EventUtils;
      }
    },

    created() {
      this.eventTime.id = this.$route.params.id;
      this.load();
    },
    
    methods: {

      async saveEventTimeTicketType() {
        this.editEventTimeTicketType = false;
        if (this.eventTimeTicketType.id) {
          await Api.post(this, "EventTimeTicketType", "update", this.eventTimeTicketType);
        } else {
          await Api.post(this, "EventTimeTicketType", "create", this.eventTimeTicketType);
        }
        this.readTicketTypes();
      },

      async deleteEventTimeTicketType() {
        this.editEventTimeTicketType = false;
        await Api.post(this, "EventTimeTicketType", "delete", this.eventTimeTicketType);
        this.readTicketTypes();
      },

      selectTicketType(eventTimeTicketType) {
        this.editEventTimeTicketType = true;
        this.eventTimeTicketType = eventTimeTicketType;
      },

      avail(ticketType, type) {
        var avail = this.availability[ticketType.id];
        if (avail) {
          return avail[type];
        }
      },

      percentSold(ticketType) {
        var avail = this.availability[ticketType.id];
        if (avail) {
          return Utils.formatPercent((avail.confirmedTickets + avail.unconfirmedTickets) / avail.quantity);
        }
      },

      async readTicketTypes() {
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
        this.eventTimeTicketTypes = await Api.post(this, "EventTimeTicketType", "list", this.eventTime);
        for (let ticketType of this.ticketTypes) {
          let found = false;
          for (let eventTimeTicketType of this.eventTimeTicketTypes) {
            if (eventTimeTicketType.ticketTypeId == ticketType.id) {
              eventTimeTicketType.ticketType = ticketType;
              found = true;
            }
          }
          if (!found) {
            let eventTimeTicketType = {
              eventTimeId: this.eventTime.id,
              ticketTypeId: ticketType.id,
              quantity: ticketType.quantity,
              ticketType: ticketType,
            }
            this.eventTimeTicketTypes.push(eventTimeTicketType);
          }
        }
        var availabilities = await Api.post(this, "EventTime", "availability", this.eventTime);
        this.availability = {};
        for (var avail of availabilities) {
          var existing = this.availability[avail.ticketTypeId];
          if (!existing) {
            existing = {};
            existing.confirmedTickets = 0;
            existing.unconfirmedTickets = 0;
            existing.quantity = 0;
            existing.availableTickets = 0;
          } 
          existing.confirmedTickets += avail.confirmedTickets;
          existing.unconfirmedTickets += avail.unconfirmedTickets;
          existing.quantity += avail.quantity;
          existing.availableTickets += avail.availableTickets;
          this.availability[avail.ticketTypeId] = existing;
        }
      },

      eventLink() {
        this.$router.push("/dashboard/EventDetails/" + this.event.id);
      },

      async load() {
        this.eventTime = await Api.post(this, "EventTime", "read", this.eventTime);
        this.event.id = this.eventTime.eventId
        this.timezone = this.eventTime.timezone || this.timezone
        this.hideDates = this.eventTime.hideDates
        this.event = await Api.post(this, "Event", "read", this.event);
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
        if (this.ticketTypes && this.ticketTypes.length > 0) {
          this.ticketType = this.ticketTypes[0];
        }
        this.boxOffice.id = this.event.boxOfficeId;
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice, this.event);
        this.readTicketTypes();
        this.listTickets();
      },

      async listTickets() {
        this.tickets = await Api.post(this, "Ticket", "list", {eventTimeId: this.eventTime.id});
      },

      async updateEventTime() {
        this.eventTime.timezone = this.timezone
        this.eventTime.hideDates = this.hideDates
        this.eventTime.start = Utils.dateToDateTime(this.start);
        this.eventTime.end = Utils.dateToDateTime(this.end);
        this.eventTime = await Api.post(this, "EventTime", "update", this.eventTime);
        this.showEdit(false);
      },

      showEdit(show) {
        if (show) {
          this.start = Utils.dateToIso(this.eventTime.start);
          this.end = Utils.dateToIso(this.eventTime.end);
        } 
        this.showEditToggle = show;
      },

      selectItem(ticket) {
        this.$router.push("/Dashboard/Ticket/" + ticket.id)
      },

      async deleteEventTime() {
        await Api.post(this, "EventTime", "delete", this.eventTime);
        this.$router.push("/Dashboard/EventTimes/" + this.eventTime.eventId)
      },
    }
  }
</script>
